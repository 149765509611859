<template>
  <div>
    <topBarY />
    <div class="bg">
      <img src="../../assets/product/industry1.png" alt="">
    </div>
    <div class="switchBar">
      <div class="tab cabinet" :class="{ active: switchIndex == 1 }" @click="switchClick(1)">吸顶式无线接入点</div>
      <div class="tab wireless" :class="{ active: switchIndex == 2 }" @click="switchClick(2)">无线接入点控制器</div>
      <div class="tab gateway" :class="{ active: switchIndex == 3 }" @click="switchClick(3)">工业级室外无线接入点</div>
      <router-link to="/productCenter" class="tab more">更多产品</router-link>
    </div>
    <div class="line"></div>
    <div class="switchContiner">
      <div class="continer cabinetContiner" v-if="switchIndex == 1">
        <div class="continer">
          <div class="introduce">
            <span class="tit">
              吸顶式无线接入点
            </span>
            <br>
            <br>
            <span class="txt">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;AP780N-W600外形简洁美观，方便与各种室内环境搭配安装，支持吸顶安<br>
              装。结构件采用优质塑胶材料，美观大方、坚固耐用。支持POE远程受电模式，<br>
              简化安装要求、降低部署成本。设备提供两个RJ45标准以太网接口。两个以太<br>
              网接口均支持POE供电，便于施工。设备内置双射频模块，同时支持2.4G频段<br>
              和5.8G频段。可满足大部分顾客的需求。并选配支持无线网络管理系统，选配<br>
              宁讯无线控制器，搭配车载移动单元可快速搭建支持移动快速切换漫游的无线<br>
              覆盖网络。
            </span>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <span class="txt">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="weight">多种工作模式：</span>支持接入点（自动WDS）、智能快速移动漫游等工作模式。<br>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="weight">高效的私有无线传输协议：</span>私有无线传输协议，通过有序的轮询每个终端，<br>
              消除了标准WIFI协议的无线碰撞，提供最佳的多用户接入速率和更低的延时。<br>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="weight">支持智能切换接入模式：</span>可配合宁讯无线控制器，进行AP大面积覆盖。控<br>
              制器漫游机制优化配置也可支持第三方移动客户端漫游连接。<br>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="weight">移动快速切换：</span>搭配优化智能算法的车载移动单元可搭建支持移动快速切<br>
              换漫游的无线覆盖网络。<br>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="weight">内置天线：</span>内置极化天线，依照不同环境可调整功率覆盖边缘距离以达到<br>
              最佳使用效果<br>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="weight">多种供电模式：</span>外接12V直流供电、，支持POE以太网接口供电<br>
            </span>
          </div>
          <img src="../../assets/product/p1.png" alt="">
        </div>
      </div>
      <div class="continer wirelessContiner" v-if="switchIndex == 2">
        <div class="continer">
          <div class="introduce">
            <span class="tit">
              无线接入点控制器
            </span>
            <br>
            <br>
            <span class="txt">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;AC-WG958系列无线接入点控制器是宁讯推出的以CAPWAP 瘦AP架构协议开<br>
              发的专为解决复杂庞大的AP管理、配置而设计的无线AP管理设备。该设备能够自<br>
              动发现网络中的AP,轻松的将无线参数 和安全设置下发到网络中的所有AP,同时可以<br>
              自动检测网络中的AP的状态。AC控制器拥有网络故障 测、链路检测功能，保证AP<br>
              能够正常工作。AC-WG958可自动识别接入点、自动配置接入点的发射功率和信道<br>
              等无需用户关注的无线参数。图形化管理界面使用户能够直观的获取网络状况、降<br>
              低业务数据配置难度。
            </span>
            <br>
            <br>
            <br>
            <br>
            <br>
            <span class="tit">
              产品特点
            </span>
            <br>
            <br>
            <span class="txt">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="weight">易用性设计：</span><br>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;自动识别接入点、自动配置接入点的发射功率和信道无需用户关注的无线<br>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;参数。<br>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;图形化界面使用户能够更直观的获取网络状况、降低业务数据配置难度。<br>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="weight">支持移动漫游：</span><br>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;内置漫游模块，可以定义漫游阀值进行客户端漫游管理，快速的实现同SS<br>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ID之间的漫游切换。<br>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;支持移动车载漫游机制，优化车载移动单元在移动传输业务中传输的稳定<br>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;性。<br>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="weight">无线设备管理：</span><br>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;最大支持512个授权AP连接部署。<br>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;支持APN780-2M、AP780-240等授权瘦AP接入。<br>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;配置信道、SSID以及加密管理等基本功能。<br>
            </span>
          </div>
          <img src="../../assets/product/p11.png" alt="">
        </div>
      </div>
      <div class="continer gatewayContiner" v-if="switchIndex == 3">
        <div class="continer">
          <div class="introduce">
            <span class="tit">
              工业级室外无线接入点
            </span>
            <br>
            <br>
            <span class="txt">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;无线快速切换基站系列产品是由宁讯推出的一款高性能、高稳定性的无线<br>
              接入点产品，专用于室内外无线覆盖。产品具有可靠、稳定、功能丰富的操作<br>
              系统，可以快速、安全、高效的提供稳定的无线网络连接。此外，产品还支持<br>
              智能切换接入模式，配合无线切换控制器可使连接客户进行快速切换漫游。
            </span>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <span class="tit">
              产品特点
            </span>
            <br>
            <br>
            <span class="txt">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="weight">多种工作模式：</span><br>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;支持接入点（自动WDS）、智能快速移动漫游等工作模式。<br>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="weight">高效的私有无线传输协议：</span><br>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;私有无线传输协议，通过有序的轮询每个终端，消除了标准WIFI协议的无<br>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;线碰撞，提供最佳的多用户接入速率和更低的延时<br>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="weight">支持智能切换接入模式：</span><br>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;可配合宁讯无线控制器，进行AP大面积覆盖。控制器漫游机制优化配置也<br>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;可支持第三方移动客户端漫游连接。<br>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="weight">移动快速切换：</span><br>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;搭配优化智能算法的车载移动单元可搭建支持移动快速切换漫游的无线覆<br>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;盖网络。<br>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="weight">丰富的内置实用工具：</span><br>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;采用HTML5设计，内置天线校准，站点扫描，延时重启，Ping，路由追踪<br>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;，频谱分析等实用的安装辅助工具，为场地勘察和链路开通提供科学分析。<br>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="weight">选配内置或外置天线：</span><br>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;根据不同场景选配不同增益的内置或外置天线、全向或定向天线，达到最<br>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;佳使用效果。<br>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="weight">多种供电模式：</span><br>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;外接12-24V直流供电，支持以太网口接口供电。<br>
            </span>
          </div>
          <img src="../../assets/product/AP780N.png" alt="">
        </div>
      </div>
    </div>
    <baseBar />
  </div>
</template>

<script>
import topBarY from '@/components/topBarY.vue'
import baseBar from '@/components/baseBar.vue'

export default {
  name: 'Product',
  components: {
    topBarY,
    baseBar
  },
  data() {
    return {
      switchIndex: 1
    }
  },
  methods: {
    switchClick(index) {
      this.switchIndex = index
    }
  },
  created() {
    this.$nextTick(() => {
      // 1.禁用右键菜单
      document.oncontextmenu = new Function("event.returnValue=false");
      // 2.禁用鼠标选中
      document.onselectstart = new Function("event.returnValue=false");
      // 3.禁止键盘F12键
      document.addEventListener("keydown", function (e) {
        if (e.key == "F12") {
          e.preventDefault(); // 如果按下键F12,阻止事件
        }
      });
    });
  }
}
</script>

<style scoped>
.bg {
  position: relative;
  width: 1920px;
  height: 480px;
  overflow: hidden;
}

.bg img {
  position: absolute;
  width: 1920px;
}

.switchBar {
  height: 70px;
  background-color: #fff;
  text-align: center;
}

.switchBar .tab {
  display: inline-block;
  margin-right: 30px;
  margin-top: 15px;
  cursor: pointer;
  border-radius: 5px;
  width: 200px;
  height: 40px;
  background-color: #c3a35d;
  color: #f5f5f5;
  font-family: 'Microsoft Yahei';
  font-size: 14px;
  line-height: 40px;
}

.switchBar .cabinet {
  width: 160px;
}

.switchBar .wireless {
  width: 160px;
}

.switchBar .more {
  width: 140px;
  margin-right: 0;
}

.line {
  margin: 0 auto;
  width: 1400px;
  height: 1px;
  background-color: #c3a35d;
}

.switchContiner {
  width: 1920px;
  padding: 20px;
  background-color: #f7f7f7;
}

.switchContiner .weight{
  color: #333;
}

/* 智能保管柜 */
.switchContiner .cabinetContiner {
  margin: 0 auto;
  width: 1400px;
  min-height: 800px;
  border-radius: 5px;
  background-color: #fff;
}

.switchContiner .cabinetContiner .continer {
  position: relative;
  margin: 0 auto;
  padding-top: 40px;
  width: 1000px;
}

.switchContiner .cabinetContiner .continer .tit {
  font-size: 18px;
  margin-bottom: 20px;
}

.switchContiner .cabinetContiner .continer .txt {
  color: #999;
  font-size: 14px;
  line-height: 30px;
}

.switchContiner .cabinetContiner img {
  position: absolute;
  top: 80px;
  right: 0;
  width: 260px;
}

/* 工业无线传输 */
.switchContiner .wirelessContiner {
  margin: 0 auto;
  width: 1400px;
  min-height: 900px;
  border-radius: 5px;
  background-color: #fff;
}

.switchContiner .wirelessContiner .continer {
  position: relative;
  margin: 0 auto;
  padding-top: 40px;
  width: 1000px;
}

.switchContiner .wirelessContiner .continer .tit {
  font-size: 18px;
  margin-bottom: 20px;
}

.switchContiner .wirelessContiner .continer .txt {
  color: #999;
  font-size: 14px;
  line-height: 30px;
}

.switchContiner .wirelessContiner img {
  position: absolute;
  top: 80px;
  right: 0;
  width: 380px;
}

/* 智能网管控制 */
.switchContiner .gatewayContiner {
  margin: 0 auto;
  width: 1400px;
  min-height: 1000px;
  border-radius: 5px;
  background-color: #fff;
}

.switchContiner .gatewayContiner .continer {
  position: relative;
  margin: 0 auto;
  padding-top: 40px;
  width: 1000px;
}

.switchContiner .gatewayContiner .continer .tit {
  font-size: 18px;
  margin-bottom: 20px;
}

.switchContiner .gatewayContiner .continer .txt {
  color: #999;
  font-size: 14px;
  line-height: 30px;
}

.switchContiner .gatewayContiner img {
  position: absolute;
  top: 100px;
  right: 0;
  width: 380px;
}

/* 无线网管平台软件系列 */
.switchContiner .platformContiner {
  margin: 0 auto;
  width: 1400px;
  min-height: 600px;
  border-radius: 5px;
  background-color: #fff;
}

.switchContiner .platformContiner .continer {
  position: relative;
  margin: 0 auto;
  padding-top: 40px;
  width: 1000px;
}

.switchContiner .platformContiner .continer .tit {
  font-size: 18px;
  margin-bottom: 20px;
}

.switchContiner .platformContiner .continer .txt {
  color: #999;
  font-size: 14px;
  line-height: 30px;
}

.switchContiner .platformContiner img {
  position: absolute;
  top: 40px;
  right: 0;
  width: 400px;
}
</style>
